import sensitiveDataFilter from '@/utilities/sensitiveDataFilter'

export default function reportFailureToBugsnag (data, vueInstance) {
  const errData = JSON.stringify(sensitiveDataFilter(data))
  const error = new Error(errData)
  const reference = vueInstance.donationStore.reference
  if (reference) {
    error.name = `PaymentFailureError - Reference: ${reference}`
  } else {
    error.name = 'PaymentFailureError'
  }
  if ('$bugsnag' in vueInstance) { vueInstance.$bugsnag.notify(error) }
}
