<template>
  <vue-recaptcha
    :sitekey="recaptchaPublicKey"
    :load-recaptcha-script="true"
    @verify="onVerify"
    @expired="onExpired"
    size="invisible"
    ref="invisibleRecaptcha"
    recaptcha-host="www.recaptcha.net"
  >
    <button
      class="submit"
      @click="submit"
      :class="{ disabled: !donationValid }"
    >
      {{ $t('donationForm.confirm') }}
    </button>
  </vue-recaptcha>
</template>

<script>
import storeDonation from '@/lib/donation/storeDonation'
import { VueRecaptcha } from 'vue-recaptcha'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

export default {
  name: 'SubmitButton',
  components: { VueRecaptcha },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const recaptchaPublicKey = ref(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY)
    const recaptchaResponse = ref(null)
    const recaptchaTimout = ref(null)

    return {
      donationStore, paymentStore, rootStore, recaptchaPublicKey, recaptchaResponse, recaptchaTimout
    }
  },
  computed: {
    thankYouPath () { return this.$t('routeNames.thankYou') },
    donationValid () { return this.rootStore.formValid },
    isSuspectedBot () { return !this.recaptchaResponse },
  },
  emits: ['submitted'],
  methods: {
    submit () {
      if (this.donationValid) {
        this.paymentStore.setPaymentProcessing(true)
        if (this.isSuspectedBot) {
          this.paymentStore.setPaymentSuccess(false)
          this.paymentStore.setPaymentProcessing(false)
          this.paymentStore.setComplete(true)
          this.paymentStore.setPaymentResult(this.$t('paymentStatus.suspectedBot'))
        } else {
          storeDonation(this, localStorage)
          this.$emit('submitted', this.recaptchaResponse)
        }
      } else {
        this.touchFields()
      }
    },
    touchFields () {
      this.rootStore.touchValidationFields(true)
      if (document.querySelector('div.error')) {
        this.$scrollToElement('.error')
      } else {
        this.$scrollToTop()
      }
      this.$nextTick(function () {
        this.rootStore.touchValidationFields(false)
      })
    },
    onVerify (response) {
      this.recaptchaTimout = false
      this.recaptchaResponse = response
    },
    onExpired () {
      this.recaptchaTimout = true
      this.$refs.invisibleRecaptcha.reset()
      this.recaptchaResponse = null
    },
    executeRecaptcha () {
      if (typeof(grecaptcha) === "undefined") {
        setTimeout(() => { this.executeRecaptcha() }, 500)
      } else {
        this.$refs.invisibleRecaptcha.execute()
      }
    }
  },
  watch: {
    donationValid: function (valid) {
      if (valid && (this.$refs['invisibleRecaptcha'])) { this.executeRecaptcha() }
      this.$scrollToElement('.submit')
    },
    recaptchaTimout: function (val) {
      if (val && (this.$refs['invisibleRecaptcha'])) {
        this.$refs.invisibleRecaptcha.execute()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  button.submit {
    border: none;
    padding: 0.6em 1em;
    text-align: center;
    width: 100%;
    margin-top: 1em;
    background-color: orange;
    color: black;
    text-shadow: 0 1px 0 white;
    box-shadow: 2px 2px 7px rgba(black, 0.3);

    &.disabled {
      opacity: 0.7;
    }
  }
</style>
