class StripeApiKeyError extends Error {
  constructor (message) {
    super(message)
    this.name = 'StripeApiKeyError'
  }
}

function verifyPublicKey (stripe, apiKey) {
  try {
    stripe.createToken('pii', { personal_id_number: 'test' })
      .then(result => {
        if (result.token) {
          console.log('valid stripe apiKey')
        } else {
          throw new StripeApiKeyError(`${apiKey} - Invalid`)
        }
      })
  } catch (error) {
    console.log('Invalid stripe apiKey')
  }
}

export {
  verifyPublicKey
}
