import { defineStore } from 'pinia'

export const usePaymentStore = defineStore('payment', {
  namespaced: true,
  state: () => {
    return {
      complete: false,
      paymentProcessing: null,
      paymentSuccess: null,
      paymentResult: null,
      details: {}
    }
  },
  getters: {},
  actions: {
    setDetails (payload) { this.details = payload },
    setComplete (payload) { this.complete = payload },
    setPaymentProcessing (payload) { this.paymentProcessing = payload },
    setPaymentSuccess (payload) { this.paymentSuccess = payload },
    setPaymentResult (payload) { this.paymentResult = payload },
    reset () {
      this.details = {}
      this.complete = false
      this.paymentProcessing = false
      this.processingComplete = false
      this.paymentSuccess = null
      this.paymentResult = null
    }
  }
})
