<template>
  <div class="stripe-payment">
    <div
      class="payment-element"
      ref="element"
    />
    <SubmitButton @submitted="processDonation" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { v4 as uuid } from 'uuid'
import { verifyPublicKey } from './methods'
import axios from 'axios'
import reportFailureToBugsnag from '@/utilities/reportFailureToBugsnag'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'StripeComponent',
  components: { SubmitButton },
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const stripe = null
    const elements = null
    return { donationStore, paymentStore, stripe, elements }
  },
  computed: {
    thankYouPath () { return this.$t('routeNames.thankYou') }
  },
  methods: {
    handleError (err) {
      reportFailureToBugsnag(err, this)
      this.paymentStore.setPaymentSuccess(false)
      this.paymentStore.setPaymentProcessing(true)
      this.paymentStore.setComplete(true)
      this.paymentStore.setPaymentResult(err) // Error message / object should contain donation reference
    },
    async handleSuccess () {
      this.paymentStore.setPaymentSuccess(true)
      this.paymentStore.setPaymentProcessing(false)
      this.paymentStore.setComplete(true)
      await this.$router.push({ name: this.thankYouPath })
    },
    async processDonation (recaptchaResponse) {
      const { error: submitError } = await this.elements.submit()
      if (submitError) { this.handleError(submitError); return; }
      const elements = this.elements

      let serializeResponse = null
      try {
        serializeResponse = await axios.post(
          `${process.env.VUE_APP_BACKEND_HOST}/stripe/serialize`,
          {
            donation: this.donationStore.donationParams,
            location: this.$l10n.currentLocation.key,
            language: this.$l10n.currentLanguage
          }
        )
      } catch (error) {
        const failureMessage = error.response ? error.response.data : error
        const errMessage = (('message' in failureMessage) ? failureMessage.message : failureMessage)
        this.handleError(errMessage)
        return
      }
      const paymentIntentClientSecret = serializeResponse.data.payment_intent_client_secret
      const returnUrl = `${location.origin}/callbacks/stripe/authorised?l=${this.$l10n.currentLocation.key}&stripe_redirect=true`

      if (this.donationStore.type === 'oneOff') {
        const paymentIntentId = serializeResponse.data.payment_intent_id
        this.donationStore.setGatewayId(paymentIntentId)
      } else {
        const subscriptionId = serializeResponse.data.subscription_id
        this.donationStore.setGatewayId(subscriptionId)
      }

      const { error: confirmError } = await this.stripe.confirmPayment({
        elements,
        clientSecret: paymentIntentClientSecret,
        confirmParams: {
          return_url: returnUrl
        },
        redirect: "if_required",
      })

      if (confirmError) {
        this.handleError(confirmError);
        return;
      }

      const paramsWithRecaptcha = Object.assign({}, {
        ...this.donationStore.donationParams,
        payment: { adapter: 'stripe' },
        location: this.$l10n.currentLocation.key,
        language: this.$l10n.currentLanguage,
        recaptcha_response: recaptchaResponse
      })
      let response = null
      try {
        response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation`, paramsWithRecaptcha)
      } catch (error) {
        const failureMessage = error.response ? error.response.data : error
        const errMessage = (('message' in failureMessage) ? failureMessage.message : failureMessage)
        this.handleError(errMessage)
        return
      }

      this.handleSuccess()
    }
  },
  mounted () {
    const config = this.$l10n.paymentMethods.stripe
    const apiKey = process.env[config.apiKey]
    this.donationStore.setReference(uuid())
    this.paymentStore.setComplete(false)
    this.$loadScript('https://js.stripe.com/v3/')
      .then(() => {
        this.stripe = new Stripe(process.env[config.apiKey], { locale: config.locale })
        verifyPublicKey(this.stripe, apiKey);
        return this.stripe
      })
      .then(async () => {
        const amountInCents = this.$l10n.amountInCents(this.donationStore.amount, this.donationStore.currency)
        const options = {
          currency: this.donationStore.currency,
          amount: amountInCents,
          paymentMethodCreation: 'manual',
          appearance: { theme: 'flat' },
          layout: { type: 'accordion', defaultCollapsed: false, radios: false, spacedAccordionItems: true }
        };
        const elementsMode = (this.donationStore.type === 'oneOff') ? 'payment' : 'subscription'
        const elements = this.stripe.elements({ mode: elementsMode, ...options })
        this.elements = elements
        const paymentElement = elements.create('payment', { defaultValues: { billingDetails: { address: { postal_code: this.donationStore.zip, country: this.donationStore.country } } } });
        paymentElement.mount(this.$refs.element)
        paymentElement.on('change', async ({ complete }) => { this.paymentStore.setComplete(complete) })
      })
  }
}
</script>

<style scoped>
  .payment-element {
    background-color: white;
    padding: 1rem;
  }
</style>
