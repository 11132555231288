<template>
  <div class="callback">
    <component
      :is="component"
      v-if="component"
    />
  </div>
</template>

<script>
import PaypalAuthorisedCallback from '@/payments/paypal/callbacks/Authorised'
import StripeAuthorisedCallback from '@/payments/stripe/callbacks/Authorised'
import { markRaw } from "vue";
import { ref } from 'vue'

export default {
  name: 'PaymentCallback',
  setup () {
    const component = ref(null)
    return { component }
  },
  props: {
    adapter: { type: String, required: true },
    callback: { type: String, required: true }
  },
  components: {
    PaypalAuthorisedCallback,
    StripeAuthorisedCallback
  },
  methods: {
    getComponent () {
      const payment = this.adapter
      const callback = this.callback
      if ((payment === 'paypal') && (callback === 'authorised')) {
        this.component = markRaw(PaypalAuthorisedCallback)
      } else if (payment === 'stripe' && (callback === 'authorised')) {
        this.component = markRaw(StripeAuthorisedCallback)
      }
    }
  },
  created () {
    this.getComponent()
  },
  watch: {
    adapter () { this.getComponent() },
    callback () { this.getComponent() }
  }
}
</script>
