<template>
  <div id="donation">
    <DonationHeader>
      <LoadingPanel ref="loadingpanel">
        <template #loading>
          <font-awesome-icon
            icon="spinner"
            pulse
          />
          <h4>{{ $t('paymentStatus.processing') }}</h4>
        </template>
        <template #failure="failure">
          <h4>{{ $t('paymentStatus.failure') }}</h4>
          <span class="error">{{ failure.result.message }}</span>
          <button @click="resetPayment">
            {{ $t('paymentStatus.changePaymentMethod') }}
          </button>
        </template>
      </LoadingPanel>
      <div class="columns">
        <div class="column">
          <ProgressTracker :current-step="3" />
          <DonationSummary />
          <PaymentMethodSelect ref="paymentmethodselect" />
          <p class="donation-nav">
            <router-link
              :to="{ name: $t('routeNames.personalDetails') }"
              class="btn btn-primary back"
            >
              <font-awesome-icon icon="arrow-left" />
              {{ $t('donationForm.back') }}
            </router-link>
          </p>
        </div>
      </div>
    </DonationHeader>
    <DonationFooter />
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { v4 as uuid } from 'uuid'
import DonationFooter from './donation/layout/DonationFooter'
import DonationHeader from './donation/layout/DonationHeader'
import DonationSummary from '@/components/donation/DonationSummary'
import LoadingPanel from '@/components/LoadingPanel'
import PaymentMethodSelect from '@/components/donation/PaymentMethodSelect'
import ProgressTracker from '@/components/donation/ProgressTracker'

export default {
  name: 'PaymentDetails',
  components: {
    DonationFooter,
    DonationHeader,
    PaymentMethodSelect,
    LoadingPanel,
    ProgressTracker,
    DonationSummary
  },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()

    return { donationStore, paymentStore, rootStore }
  },
  methods: {
    resetPayment () {
      this.donationStore.setReference(uuid())
      this.$refs.loadingpanel.reset()
      this.$refs.paymentmethodselect.resetPayment()
    }
  },
  mounted () {
    this.$scrollToElement('.column')
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    @include on-desktop(){
      display: flex;
      margin: 0 -0.5rem;
    }

    .column {
      margin-bottom: 1.5rem;

       @include on-desktop(){
        flex: 1;
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }
</style>
