<template>
  <div class="page-footer">
    <div class="dontions-go-towards">
      <div class="container contain">
        <header class="side-by-side">
          <h4>{{ $t('footer.dontions-go-towards.header.title') }}</h4>
        </header>
        <div class="cards">
          <div class="vert-cards left">
            <div class="card">
              <div class="content">
                <h4>{{ $t('footer.dontions-go-towards.cards.1.title') }}</h4>
                <p>{{ $t('footer.dontions-go-towards.cards.1.content') }}</p>
              </div>
            </div>
            <div class="card">
              <div class="content">
                <h4>{{ $t('footer.dontions-go-towards.cards.2.title') }}</h4>
                <p>{{ $t('footer.dontions-go-towards.cards.2.content') }}</p>
              </div>
            </div>
          </div>
          <div class="center-card">
            <div class="circle">
              <div
                class="content"
                :title="$t('footer.dontions-go-towards.image.title')"
              />
            </div>
          </div>
          <div class="vert-cards right">
            <div class="card">
              <div class="content">
                <h4>{{ $t('footer.dontions-go-towards.cards.3.title') }}</h4>
                <p>{{ $t('footer.dontions-go-towards.cards.3.content') }}</p>
              </div>
            </div>
            <div class="card">
              <div class="content">
                <h4>{{ $t('footer.dontions-go-towards.cards.4.title') }}</h4>
                <p>{{ $t('footer.dontions-go-towards.cards.4.content') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="more-ways-to-give"
      id="moreWaysToGive"
    >
      <div class="container contain">
        <header
          v-if="$t('footer.more-ways-to-give.header.title').length > 0"
          class="side-by-side"
        >
          <h2>{{ $t('footer.more-ways-to-give.header.title') }}</h2>
          <div>
            <p>{{ $t('footer.more-ways-to-give.header.note') }}</p>
          </div>
        </header>
        <div class="cards">
          <template
            v-for="(card, index) in Object.keys($tm('footer.more-ways-to-give.cards'))"
            :key="card"
          >
            <div class="card">
              <img
                :src="$t(`footer.more-ways-to-give.cards.${card}.imgSrc`)"
                v-if="$tm(`footer.more-ways-to-give.cards.${card}.imgSrc`)[0]"
              >
              <template v-if="$tm(`footer.more-ways-to-give.cards.${card}.linkSrc`)[0]">
                <a
                  :href="$t(`footer.more-ways-to-give.cards.${card}.linkSrc`)"
                  target="_bank"
                >
                  <h4 v-if="index < 3">{{ $t(`footer.more-ways-to-give.cards.${card}.title`) }}</h4>
                  <h5 v-else>{{ $t(`footer.more-ways-to-give.cards.${card}.title`) }}</h5>
                </a>
                <p v-html="$t(`footer.more-ways-to-give.cards.${card}.content`)" />
              </template>
              <template v-else>
                <h4 v-if="index < 3">
                  {{ $t(`footer.more-ways-to-give.cards.${card}.title`) }}
                </h4>
                <h5
                  class="h4"
                  v-else
                >
                  {{ $t(`footer.more-ways-to-give.cards.${card}.title`) }}
                </h5>
                <p v-html="$t(`footer.more-ways-to-give.cards.${card}.content`)" />
              </template>
            </div>
            <hr v-if="index === 2">
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonationFooter'
}
</script>

<style lang="scss" scoped>
  header.side-by-side {
    margin-top: 0;
    margin-bottom: 0;

    h2 {
      font-size: 3.125rem;
    }

    h4 {
      color: #fff;
      font-family: $titles-font-family-medium;
    }
  }
  .dontions-go-towards{
    background-color: #fff;

    @include on-non-mobile(){
      margin-top: -4rem;
    }

    .container {
      background-color: $color-tall-poppy;
      padding: 1rem 1rem;

      @include on-tablet(){
        padding: 1rem 1.5rem;
      }

      @include on-desktop(){
        padding: 1rem 4.5rem;
      }
    }
    header{
      h2{
        color: #fff;
        font-family: $titles-font-family-medium;
        font-size: 3.125rem;
      }
    }

    .cards{
      margin: 0 -1rem;
      @include on-non-mobile(){
        margin: 1.5rem -1rem;
        display: flex;
        justify-content: center;
      }

      .vert-cards{
        display: flex;
        flex: 1 1 30%;
        flex-direction: column;

        @include on-desktop(){
          &.left{
            .card:after{
              content: '';
              display: block;
              background-image: url('https://assets.survivalinternational.org/viru/layout/right-chevron.svg');
              margin-left: 1rem;
            }
          }
          &.right{
            .card:before{
              content: '';
              display: block;
              background-image: url('https://assets.survivalinternational.org/viru/layout/left-chevron.svg');
              margin-right: 1rem;
            }
          }
        }
      }
      h4, p{
        color: #fff;
      }
      .card{
        margin: 1rem;
        display:flex;
        flex: 1 1 50%;

        .content{
          flex: 1 1 80%;

          h4{
            font-family: $titles-font-family-medium;
            margin-top: 0;
            margin-bottom: 1rem
          }
          p{
            margin: 0;
          }
        }
        &:after, &:before{
          flex: 1 0 20%;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
      .center-card{
        display: none;

        @include on-desktop(){
          display: block;
          flex: 1 1 30%;
          align-self: center;
          margin: auto -1rem;
        }

        .circle{
          width: 100%;
          padding-top: 100%; /* 1:1 Aspect Ratio */
          font-family: $titles-font-family-medium;
          color: $color-tall-poppy;
          font-size: 3rem;
          border-radius: 50% 50%;
          position: relative;
          background-image: url('https://assets.survivalinternational.org/viru/img/donations-go-towards.jpg');
          background-size: cover;
          background-position: center center;

          .content{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
  .more-ways-to-give {
    background-color: #fff;

    @include on-desktop(){
      padding-top: 2.5rem;
    }

    .container {
      background-color: $color-body-bg;
      padding: 1rem 1rem;

      @include on-tablet() {
        padding: 1rem 1.5rem;
      }

      @include on-desktop() {
        padding: 1rem 4.5rem;
      }
    }
    .cards {
      margin: 1.5rem -1rem;

      @include on-non-mobile() {
        display: flex;
        flex-wrap: wrap;
      }

      hr {
        width: 100%;
        margin: 1rem 0;
        border: 0;
        height: 2px;
        background-color: rgba($color-dune, 0.1);
      }
      .card {
        flex: 1 1 30%;
        margin: 1rem;
        display: flex;
        flex-direction: column;

        img {
          margin: 0 auto 0 0;
          height: 35px;
        }

        p {
          margin: 0;
          font-size: 1rem;
          a {
            // Why is this not applied!?
            color: $color-blue-lagoon !important;
          }
        }
        h4, h5 {
          margin: 1rem 0;
        }
      }
    }
  }
</style>
