import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import ThankYou from './views/ThankYou'
import DonationIntro from './views/DonationIntro'
import PersonalDetails from './views/PersonalDetails'
import PaymentDetails from './views/PaymentDetails'
import SmartDebitConfirmation from './views/SmartDebitConfirmation'
import PaymentCallback from './views/PaymentCallback'
import WithNavigation from './views/layout/WithNavigation'
import { useDonationStore } from '@/stores/donation'

function requireDonation (_to, _from, next) {
  const donationStore = useDonationStore()
  if (donationStore.amount) {
    next()
  } else {
    next('/')
  }
}

export function createRouter () {
  return _createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        component: WithNavigation,
        children: [
          { path: '', name: 'donationIntro', component: DonationIntro },

          { path: 'ihre-angaben', name: 'personalDetailsDe', component: PersonalDetails, beforeEnter: requireDonation },
          { path: 'personal-information', name: 'personalDetailsEn', component: PersonalDetails, beforeEnter: requireDonation },
          { path: 'informacion-personal', name: 'personalDetailsEs', component: PersonalDetails, beforeEnter: requireDonation },
          { path: 'informations-personnelles', name: 'personalDetailsFr', component: PersonalDetails, beforeEnter: requireDonation },
          { path: 'informazione-personale', name: 'personalDetailsIt', component: PersonalDetails, beforeEnter: requireDonation },
          { path: 'dados-pessoas', name: 'personalDetailsBr', component: PersonalDetails, beforeEnter: requireDonation },

          { path: 'zahlungsinformationen', name: 'paymentDetailsDe', component: PaymentDetails, beforeEnter: requireDonation },
          { path: 'payment-information', name: 'paymentDetailsEn', component: PaymentDetails, beforeEnter: requireDonation },
          { path: 'informacion-del-pago', name: 'paymentDetailsEs', component: PaymentDetails, beforeEnter: requireDonation },
          { path: 'informations-de-paiement', name: 'paymentDetailsFr', component: PaymentDetails, beforeEnter: requireDonation },
          { path: 'informazioni-sul-pagamento', name: 'paymentDetailsIt', component: PaymentDetails, beforeEnter: requireDonation },
          { path: 'informacao-de-pagamento', name: 'paymentDetailsBr', component: PaymentDetails, beforeEnter: requireDonation },

          { path: 'danke', name: 'thankYouDe', component: ThankYou, beforeEnter: requireDonation },
          { path: 'thank-you', name: 'thankYouEn', component: ThankYou, beforeEnter: requireDonation },
          { path: 'gracias', name: 'thankYouEs', component: ThankYou, beforeEnter: requireDonation },
          { path: 'merci', name: 'thankYouFr', component: ThankYou, beforeEnter: requireDonation },
          { path: 'grazie', name: 'thankYouIt', component: ThankYou, beforeEnter: requireDonation },
          { path: 'obrigado', name: 'thankYouBr', component: ThankYou, beforeEnter: requireDonation },
          {
            path: 'direct-debit-confirmation',
            name: 'smartDebitConfirmation',
            component: SmartDebitConfirmation,
            beforeEnter: requireDonation
          }
        ]
      },
      {
        path: '/callbacks/:adapter/:callback',
        component: PaymentCallback,
        props: true
      },
      { path: '/:pathMatch(.*)*', name: 'not-found', component: DonationIntro }
    ]
  })
}
