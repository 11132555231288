import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter } from './router'
import { createHead } from '@vueuse/head'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faArrowRight, faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import App from './App.vue'
import l10n from './plugins/localisation'
import { i18n } from './i18n'
import LoadScript from './plugins/loadScript'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import './animation'

const router = createRouter()
const head = createHead()
const app = createApp({ ...App })

library.add(faSpinner, faArrowRight, faArrowLeft, faCheck)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(l10n, i18n)
app.use(LoadScript)
app.use(head)

app.config.globalProperties.$clipboard = window.navigator.clipboard
app.config.globalProperties.$scrollToTop = (x = 0, y = 0) => window.scrollTo(x, y)
app.config.globalProperties.$scrollToElement = (selector) =>
  document.querySelector(selector).scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest'
  })

if (process.env.VUE_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()]
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(app)

  app.config.globalProperties.$bugsnag = Bugsnag
}

app.mount('#app')
