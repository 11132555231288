<template>
  <div class="sepa-payment">
    <small v-if="$tm('payment.methods.sepa.msg')[0]">{{ $t('payment.methods.sepa.msg') }}</small>
    <div>
      <FormField
        :label="showAsteriskIfRequired('iban') + $t('donationForm.details.sepa.iban.label')"
        v-model="sepa.iban"
        :validation="v$.sepa.iban"
        theme="light"
      />
    </div>
    <div
      class="confirm-payment-holder"
      v-if="$l10n.currentLocation.formFields.confirmPaymentHolderRequired"
    >
      <CheckBox
        :label="$t('payment.confirmPaymentHolder')"
        v-model="assumedPaymentHolder"
      />
    </div>
    <SubmitButton @submitted="processDonation" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import CheckBox from '@/components/form/CheckBox'
import FormField from '@/components/form/FormField'
import Iban from 'iban'
import reportFailureToBugsnag from '@/utilities/reportFailureToBugsnag'
import SubmitButton from '@/components/form/SubmitButton'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'SepaComponent',
  components: { FormField, CheckBox, SubmitButton },
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const sepa = ref({ iban: '' })
    const assumedPaymentHolder = ref(true)
    return {
      donationStore, paymentStore, v$: useVuelidate(), sepa, assumedPaymentHolder
    }
  },
  validations: {
    sepa: {
      iban: {
        required,
        iban: value => Iban.isValid(value)
      }
    }
  },
  methods: {
    handleError (err) {
      reportFailureToBugsnag(err, this)
      this.paymentStore.setPaymentSuccess(false)
      this.paymentStore.setPaymentProcessing(true)
      this.paymentStore.setComplete(true)
      this.paymentStore.setPaymentResult(err) // Error message / object should contain donation reference
    },
    async handleSuccess () {
      this.paymentStore.setPaymentSuccess(true)
      this.paymentStore.setPaymentProcessing(false)
      this.paymentStore.setComplete(true)
      await this.$router.push({ name: this.thankYouPath })
    },
    async processDonation (recaptchaResponse) {
      const paramsWithRecaptcha = Object.assign({}, {
        ...this.donationStore.donationParams,
        payment: { adapter: 'sepa', iban_number: this.paymentStore.details.iban },
        location: this.$l10n.currentLocation.key,
        language: this.$l10n.currentLanguage,
        recaptcha_response: recaptchaResponse
      })

      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation`, paramsWithRecaptcha)
      } catch (error) {
        const failureMessage = error.response ? error.response.data : error
        const errMessage = (('message' in failureMessage) ? failureMessage.message : failureMessage)
        this.handleError(errMessage)
        return
      }
      this.handleSuccess()
    },
    showAsteriskIfRequired (field) {
      const fieldRequired = this.v$.sepa[field].required
      if (this.v$.sepa[field] === 'none') {
        return '* '
      }
      return (fieldRequired && fieldRequired.$invalid) ? '* ' : ''
    }
  },
  computed: {
    thankYouPath () { return this.$t('routeNames.thankYou') },
    sepaInvalid () {
      if (this.assumedPaymentHolder === false) { return true }
      return this.v$['sepa'] ? this.v$.sepa.$invalid : true
    }
  },
  watch: {
    'sepa.iban': function (val) {
      if (val) {
        this.sepa.iban = val.toUpperCase().replace(/[^A-Za-z0-9]/g, '')
        this.paymentStore.setDetails({ ...this.sepa })
      }
    },
    validateFields: function () { this.v$.$touch() },
    sepaInvalid: function (val) { this.paymentStore.setComplete(!val) }
  },
  mounted () {
    this.donationStore.setReference(uuid())
    this.paymentStore.setComplete(false)
    if (this.$l10n.currentLocation.formFields.confirmPaymentHolderRequired) { this.assumedPaymentHolder = false }
  }
}
</script>

<style lang="scss" scoped>
  a {
    color: $color-web-orange;
    &:visited {
      color: $color-web-orange;
    }
  }

  .confirm-payment-holder {
    margin-top: 0.5rem;
  }
</style>
