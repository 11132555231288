import axios from 'axios'

export default function awaitUpdate (vueInstance, reference) {
  vueInstance.$scrollToElement('.loading-panel')

  return new Promise((resolve) => {
    let result = null
    const check = async () => {
      try {
        result = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/donation/${reference}/status`)
      } catch (error) {
        vueInstance.paymentStore.setPaymentSuccess(false)
        vueInstance.paymentStore.setPaymentProcessing(true)
        vueInstance.paymentStore.setComplete(true)
        vueInstance.paymentStore.setPaymentResult(error.message)
        return
      }

      if (result.data.status !== 'pending') {
        if (result.data.status === 'failed') {
          vueInstance.paymentStore.setPaymentSuccess(false)
          vueInstance.paymentStore.setPaymentProcessing(true)
          vueInstance.paymentStore.setComplete(true)
          vueInstance.paymentStore.setPaymentResult(result.data.details)
        }
        const isIncomplete = (result.data.details.auth_required)
        if (isIncomplete) {
          vueInstance.paymentStore.setDetails(result.data.details)
          vueInstance.authorize(result)
        } 
        return resolve(result.data)
      } else {
        setTimeout(check, 500)
      }
    }
    check()
  })
}
