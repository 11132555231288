<template>
  <div
    class="payment-select"
    :class="{error: v$.$error}"
  >
    <h3 class="payment-header">
      {{ $t('payment.header') }}
    </h3>
    <div class="options form-check-group">
      <div
        class="option"
        v-for="key in Object.keys(validPaymentMethods)"
        :class="key"
        :key="key"
      >
        <input
          type="radio"
          v-model="selectedMethod"
          :value="key"
          :id="idFor(key)"
        >
        <label
          class="payment-checkbox"
          :for="idFor(key)"
        >
          <img
            :aria-label="$t(`payment.methods.${key}.name`)"
            :class="key"
            :src="$t(`payment.methods.${key}.logo`)"
            :alt="$t(`payment.methods.${key}.name`)"
            :title="$t(`payment.methods.${key}.name`)"
          >
          <div>{{ $t(`payment.methods.${key}.name`) }}</div>
        </label>
      </div>
    </div>
    <div
      class="payment"
      v-if="currentComponent"
    >
      <component
        :is="currentComponent"
        :validate-fields="validateAllFields"
      />
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { required } from '@vuelidate/validators'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { v4 as uuid } from 'uuid'
import PaypalComponent from '@/payments/paypal/PaypalComponent.vue'
import SepaComponent from '@/payments/sepa/SepaComponent.vue'
import SmartdebitComponent from '@/payments/smartdebit/SmartdebitComponent.vue'
import StripeComponent from '@/payments/stripe/StripeComponent.vue'
import TransitionSlide from '@/transitions/Slide'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'PaymentMethodSelect',
  components: { TransitionSlide, PaypalComponent, StripeComponent, SepaComponent, SmartdebitComponent },
  expose: ['resetPayment'],
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const rootStore = useRootStore()
    const selectedMethod = ref(null)
    const uniqueId = ref(uuid())
    const paymentMethods = ref({})
    const components = reactive({ stripe: 'StripeComponent', sepa: 'SepaComponent', paypal: 'PaypalComponent', smartdebit: 'SmartdebitComponent' })
    const paymentMethodShorthands = reactive({ cc: 'stripe', dd: 'smartdebit', py: 'paypal', se: 'sepa' })
    return {
      v$: useVuelidate(), donationStore, paymentStore, rootStore,
      selectedMethod, uniqueId, paymentMethods, components, paymentMethodShorthands
    }
  },
  computed: {
    donation () { return this.donationStore.currentDonation },
    currentComponent () { return this.components[this.selectedMethod] },
    validPaymentMethods () {
      var methods = this.paymentMethods
      if (this.donation.currency !== 'eur') { delete methods.sepa }
      if (this.donation.type === 'oneOff' || this.donation.currency !== 'gbp') { delete methods.smartdebit }
      if ('rmvPymnt' in this.rootStore.queryParams) {
        this.rootStore.queryParams.rmvPymnt.split(',').forEach(type => {
          let method = this.paymentMethodShorthands[type]
          delete methods[method]
        })
      }
      return methods
    },
    validateAllFields () { return this.rootStore.validateAllFields },
    paymentDetailsComplete () { return this.paymentStore.complete }
  },
  validations () {
    return {
      paymentDetailsComplete: {
        required,
        paymentDetailsComplete: value => value === true
      }
    }
  },
  watch: {
    selectedMethod (val) { if (val === null) { this.resetPayment() } },
    paymentDetailsComplete (val) {
      this.rootStore.setValidationPaymentMethodSelect(val)
      this.v$.$touch()
    },
    validPaymentMethods () {
      const currentPaymentAvailable = (Object.keys(this.validPaymentMethods).includes(this.selectedMethod))
      if (!currentPaymentAvailable) { this.resetPayment() }
    },
    validateAllFields () { this.v$.$touch() },
    'v$.$invalid': function (val) {
      this.rootStore.setValidationPaymentMethodSelect(!val)
    }
  },
  methods: {
    idFor (method) { return `element-${this.uniqueId}-${method}` },
    resetPayment () {
      this.paymentStore.reset()
      this.selectedMethod = null
    }
  },
  mounted () {
    this.paymentMethods = this.$l10n.currentLocation.paymentMethods
  }
}
</script>

<style lang="scss" scoped>
  .options {
    display: flex;
    flex-direction: column;
    padding: 0.1rem;
  }

  .option {
    margin-right: 0 !important;
    margin: 0.1rem;
    label {
      padding: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      text-align: center;
      height: 100%;

      &.payment-checkbox {
        img {
          margin-top: auto;
          display: block;
          width: 6rem;
          height: 4rem;
          object-fit: contain;
          background-color: white;
          padding: 0.25rem 0.5rem;
          border-radius: 6px;
          flex: 0 1 auto;
        }

        div {
          line-height: 1rem;
          flex: 1 1 auto;
          text-align: left;
          margin-left: 0.5rem;
        }
      }
    }

    input:checked + label {
      height: 100%;
    }
  }

  .error {
    .options {
      @include shake();
    }
  }

  .payment {
    margin-top: 1em;
  }

  .payment-header {
    margin-bottom: 0.5rem;
    color: white;
    font-family: 'Stag-medium'
  }
</style>
