import currency from 'currency.js'
import { useL10nStore } from '@/stores/l10n'

function Service ({ i18n, locations }) {
  this.i18n = i18n
  this.store = useL10nStore()
  this.availableLocations = locations

  this.loadedLocales = []
  this.locationChangeHooks = {}
}

Service.prototype = {
  _setLocale (locale) {
    this.i18n.global.locale.value = locale
    document.querySelector('html').setAttribute('lang', locale)
    return locale
  },

  get currentLocation () { return this.store.current },
  get currentLocale () { return this.i18n.global.locale },
  get currentLanguage () { return this.i18n.global.locale.value.split('-')[0] },
  get availableCurrencies () { return this.currentLocation.currencies },
  get availableFrequencies () { return this.currentLocation.donationFrequencies },
  get paymentMethods () { return this.currentLocation.paymentMethods },
  get availableCollectionDates () { return this.currentLocation.paymentMethods.smartdebit.collectionDates },

  changeLocation (location) {
    return this.store.change(location)
      .then(() => this.changeLanguage(this.currentLocation.defaultLanguage))
      .then(() => { this.i18n.fallbackLocale = this.currentLocation.currentLocale })
      .then(() => {
        Object.values(this.locationChangeHooks)
          .forEach(hook => {
            hook(this.currentLocation)
          })
      })
  },

  async changeLanguage (language) {
    const location = this.currentLocation.key
    const locale = `${language}-${location.toUpperCase()}`
    const countryList = await this.loadCountries(language)
    const statesList = await this.loadStates(location)

    if (this.i18n.global.locale === locale) { return Promise.resolve(locale) }
    if (this.loadedLocales.includes(locale)) { return Promise.resolve(this._setLocale(locale)) }

    return this.importLangFromFile(
      language, location, locale, countryList, statesList
    )
  },

  importLangFromFile (language, location, locale, countryList, statesList) {
    return import(`../../locations/lang/${language}/${location}.json`)
      .then((module) => {
        Object.assign(module.default, { countries: countryList, states: statesList })
        this.i18n.global.setLocaleMessage(locale, module.default)
        this.loadedLocales.push(locale)
        return this._setLocale(locale)
      })
  },

  loadCountries (language) {
    return import(`../../locations/lang/countries/${language}`)
      .then((module) => { return module.countries })
  },

  loadStates (location) {
    if (location !== 'us') { return {} }
    return import(`../../locations/lang/states/${location}`)
      .then((module) => { return module.states })
  },

  suggestedAmountsFor (currency, type) {
    const currencyConfig = this.currentLocation.currencies[currency]
    return currencyConfig ? currencyConfig.suggestedAmounts[type] : []
  },

  minAmountFor (currency) {
    return this.currentLocation.currencies[currency].minimum
  },

  formatCurrency (amount, cur, options = {}) {
    if (this.currentLocation.currencies && cur) {
      return currency(amount, Object.assign(
        { formatWithSymbol: true },
        this.currentLocation.currencies[cur].config,
        options
      )).format()
    }
  },

  // This is solely relying on #intValue from: https://currency.js.org/
  // If ever having to support a non-decimal currency, then this MUST change
  amountInCents (amount, cur, options = { precision: 2 }) {
    if (this.currentLocation.currencies && cur) {
      return currency(amount, Object.assign(
        { formatWithSymbol: true },
        this.currentLocation.currencies[cur].config,
        options
      )).intValue
    }
  },

  onLocationChange (name, hook, { immediate }) {
    this.locationChangeHooks[name] = hook
    if (immediate) hook(this.currentLocation)
  }
}

export default Service
