<template>
  <div class="smart-debit-payment">
    <div>
      <CollectionDateSelect v-model="smartDebit.collectionDate" />
      <FormField
        :label="showAsteriskIfRequired('accountName') + $t('donationForm.details.smartDebit.accountName.label')"
        v-model="smartDebit.accountName"
        :validation="v$.smartDebit.accountName"
      />
      <FormField
        :label="showAsteriskIfRequired('accountNumber') + $t('donationForm.details.smartDebit.accountNumber.label')"
        v-model="smartDebit.accountNumber"
        :validation="v$.smartDebit.accountNumber"
        v-mask="'########'"
      />
      <FormField
        :label="showAsteriskIfRequired('sortCode') + $t('donationForm.details.smartDebit.sortCode.label')"
        v-model="smartDebit.sortCode"
        :validation="v$.smartDebit.sortCode"
        v-mask="'## ## ##'"
      />
    </div>
    <div>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.text-part-1') }}
      </p>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.text-part-2') }}
        <a
          :href="$t('donationForm.details.smartDebit.accountHolder.downloadableForm.url')"
          target="_blank"
        >
          {{ $t('donationForm.details.smartDebit.accountHolder.downloadableForm.text') }}
        </a>
      </p>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.text') }}
        <a
          :href="$t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.url')"
          target="_blank"
        >
          {{ $t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.linkText') }}
        </a>
      </p>
    </div>
    <SubmitButton @submitted="processDonation" />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { ref } from 'vue'
import { required, minLength } from '@vuelidate/validators'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { v4 as uuid } from 'uuid'
import awaitUpdate from '@/lib/donation/awaitUpdate'
import axios from 'axios'
import CollectionDateSelect from '@/components/donation/CollectionDateSelect'
import FormField from '@/components/form/FormField'
import reportFailureToBugsnag from '@/utilities/reportFailureToBugsnag'
import SubmitButton from '@/components/form/SubmitButton'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'SmartdebitComponent',
  components: { FormField, CollectionDateSelect, SubmitButton },
  props: {
    validateFields: { type: Boolean, default: false }
  },
  directives: { mask },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const smartDebit = ref({ accountName: '', sortCode: '', accountNumber: '', collectionDate: '1' })
    return { donationStore, paymentStore, rootStore, v$: useVuelidate(), smartDebit }
  },
  validations: {
    smartDebit: {
      accountName: {
        required,
        minLength: minLength(3)
      },
      accountNumber: {
        required,
        minLength: minLength(8)
      },
      sortCode: {
        required,
        minLength: minLength(8) // 6 +2 from input mask
      }
    }
  },
  methods: {
    handleError (err) {
      reportFailureToBugsnag(err, this)
      this.paymentStore.setPaymentSuccess(false)
      this.paymentStore.setPaymentProcessing(true)
      this.paymentStore.setComplete(true)
      this.paymentStore.setPaymentResult(err) // Error message / object should contain donation reference
    },
    async handleSuccess () {
      this.paymentStore.setPaymentSuccess(true)
      this.paymentStore.setPaymentProcessing(false)
      this.paymentStore.setComplete(true)
      await this.$router.push({ name: 'smartDebitConfirmation' })
    },
    authorize () {
      this.handleSuccess()
    },
    async processDonation (recaptchaResponse) {
      const paymentDetails = {
        adapter: 'smartdebit',
        sort_code: this.paymentStore.details.sortCode.replace(/-|\s/g, ''),
        account_number: this.paymentStore.details.accountNumber,
        account_name: this.paymentStore.details.accountName,
        chosen_day: this.paymentStore.details.collectionDate
      }
      const paramsWithRecaptcha = Object.assign({}, {
        ...this.donationStore.donationParams,
        payment: { ...paymentDetails },
        location: this.$l10n.currentLocation.key,
        language: this.$l10n.currentLanguage,
        recaptcha_response: recaptchaResponse
      })
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation`, paramsWithRecaptcha)
      } catch (error) {
        const failureMessage = error.response ? error.response.data : error
        const errMessage = (('message' in failureMessage) ? failureMessage.message : failureMessage)
        this.handleError(errMessage)
        return
      }

      try { await awaitUpdate(this, this.donationStore.reference) } catch (error) {
        const failureMessage = error.response ? error.response.data : error
        const errMessage = (('message' in failureMessage) ? failureMessage.message : failureMessage)
        this.handleError(errMessage)
        return
      }
    },
    showAsteriskIfRequired (field) {
      const fieldRequired = this.v$.smartDebit[field].required
      if (this.v$.smartDebit[field] === 'none') {
        return '* '
      }
      return (fieldRequired && fieldRequired.$invalid) ? '* ' : ''
    }
  },
  computed: {
    smartDebitInvalid () { return this.v$.smartDebit ? this.v$.smartDebit.$invalid : true },
    frequency () { return this.donationStore.type }
  },
  watch: {
    smartDebitInvalid: function (val) {
      this.paymentStore.setComplete(!val)
    },
    smartDebit: {
      handler() {
        this.paymentStore.setDetails({ ...this.smartDebit })
      },
      deep: true
    },
    validateFields: function (_freq) { this.v$.$touch() },
    frequency: function () { this.smartDebit.collectionDate = '1' }
  },
  mounted () {
    this.donationStore.setType('monthly')
    this.rootStore.setOmittedFrequency('oneOff')
    this.donationStore.setReference(uuid().substring(0, 18))
    this.paymentStore.setComplete(false)
  },
  beforeUnmount () {
    this.rootStore.setOmittedFrequency(null)
  }
}
</script>

<style lang='scss' scoped>
  .smart-debit-payment {
    a {
      color: white;
    }
  }
</style>
