<template>
  <div class="donation-form">
    <TransitionSlide>
      <div
        class="full-details"
        :class="formClasses"
      >
        <h3 class="details-header">
          {{ $t('donationForm.details.header') }}
        </h3>
        <div class="name">
          <FormField
            class="forename"
            :label="showAsteriskIfRequired('forename') + $t('donationForm.details.forename.label')"
            v-model="forename"
            :validation="v$.forename"
          />
          <FormField
            class="surname"
            :label="showAsteriskIfRequired('surname') + $t('donationForm.details.surname.label')"
            v-model="surname"
            :validation="v$.surname"
          />
        </div>
        <FormField
          class="email"
          :label="showAsteriskIfRequired('email') + $t('donationForm.details.email.label')"
          v-model="email"
          type="email"
          :validation="v$.email"
        />
        <FormField
          v-if="showPhone"
          class="phone"
          :label="$t('donationForm.details.phone.label')"
          v-model="phone"
          type="tel"
          :validation="v$.phone"
        />
        <FormField
          class="street"
          :label="showAsteriskIfRequired('street') + $t('donationForm.details.street.label')"
          v-model="street"
          :validation="v$.street"
        />
        <FormField
          class="city"
          :label="showAsteriskIfRequired('city') + $t('donationForm.details.city.label')"
          v-model="city"
          :validation="v$.city"
        />
        <FormField
          class="zip"
          :label="showAsteriskIfRequired('zip') + $t('donationForm.details.zip.label')"
          v-model="zip"
          :validation="v$.zip"
        />
        <SelectField
          v-if="(Object.keys($tm('states')).length > 0) && showState"
          class="state"
          :label="showAsteriskIfRequired('state') + $t('donationForm.details.state.label')"
          :list="$tm('states')"
          v-model="state"
          :validation="v$.state"
        />
        <FormField
          v-else-if="showState"
          class="state"
          :label="$t('donationForm.details.state.label')"
          v-model="state"
          :validation="v$.state"
        />
        <SelectField
          v-if="editableCountry"
          class="country"
          :label="showAsteriskIfRequired('country') + $t('location.label')"
          :list="$tm('countries')"
          v-model="country"
          :validation="v$.country"
        />
        <div
          v-else
          class="country readonly-val"
        >
          {{ $tm('countries')[country] }}
        </div>
      </div>
    </TransitionSlide>
  </div>
</template>

<script>
import TransitionSlide from '@/transitions/Slide'
import FormField from '@/components/form/FormField'
import SelectField from '@/components/form/SelectField'
import countrycodeToOffice from '@/locations/countryCodeToOffice'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import { required, requiredIf, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { useRootStore } from '@/stores/root'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'DonationForm',
  components: {
    TransitionSlide,
    FormField,
    SelectField
  },
  setup () {
    const donationStore = useDonationStore()
    const rootStore = useRootStore()
    const l10nStore = useL10nStore()
    return { v$: useVuelidate(), donationStore, rootStore, l10nStore }
  },
  methods: {
    showAsteriskIfRequired (field) {
      const fieldRequired = this.v$[field].required
      if (this[field] === 'none') {
        return '* '
      } else if (fieldRequired && fieldRequired.$invalid) {
        return '* '
      } else {
        return ''
      }
    },
    updateOfficeFromCountryCode (countryCode) {
      const officeLocale = countrycodeToOffice[countryCode]
      this.$l10n.changeLocation(officeLocale)
    },
    zipValid (value) {
      if (postcodeValidatorExistsForCountry(this.country)) {
        return postcodeValidator(this.zip, this.country)
      } else {
        return (value === null) ? false : value.match(/\S/)
      }
    }
  },
  computed: {
    forename: {
      get () { return this.donationStore.forename },
      set (newValue) { this.donationStore.setForename(newValue) }
    },
    surname: {
      get () { return this.donationStore.surname },
      set (newValue) { this.donationStore.setSurname(newValue) }
    },
    email: {
      get () { return this.donationStore.email },
      set (newValue) { this.donationStore.setEmail(newValue) }
    },
    phone: {
      get () { return this.donationStore.phone },
      set (newValue) { this.donationStore.setPhone(newValue) }
    },
    currency: {
      get () { return this.donationStore.currency },
      set (newValue) { this.donationStore.setCurrency(newValue) }
    },
    street: {
      get () { return this.donationStore.street },
      set (newValue) { this.donationStore.setStreet(newValue) }
    },
    city: {
      get () { return this.donationStore.city },
      set (newValue) { this.donationStore.setCity(newValue) }
    },
    zip: {
      get () { return this.donationStore.zip },
      set (newValue) { this.donationStore.setZip(newValue) }
    },
    state: {
      get () { return this.donationStore.state },
      set (newValue) { this.donationStore.setState(newValue) }
    },
    country: {
      get () { return this.donationStore.country },
      set (newValue) { this.donationStore.setCountry(newValue) }
    },
    validateAllFields () { return this.rootStore.validateAllFields },
    showState () {
      return this.l10nStore.current.formFields.state.show
    },
    showPhone () {
      return this.l10nStore.current.formFields.telephone.show
    },
    hidePhoneAndState () {
      return (!this.showPhone) && (!this.showState)
    },
    formClasses () {
      if (this.hidePhoneAndState) { return { 'no-phone-or-state': true } }
      if (!this.showState) { return { 'no-state': true } }
      if (!this.showPhone) { return { 'no-phone': true } }
      return {}
    },
    editableCountry () {
      return (!this.rootStore.isEnDomain) || ('l' in this.rootStore.queryParams)
    }
  },
  validations () {
    return {
      forename: { required },
      surname: { required },
      email: { email, required },
      phone: { phone: value => value === null ? true : /^[0-9\s]*$/.test(value) },
      street: {
        required: requiredIf(function () { return this.l10nStore.current.formFields.street.required }),
        street: value => (value === null || value === '') ? true : !!value.match(/\S/)
      },
      zip: {
        required: requiredIf(function () { return this.l10nStore.current.formFields.zip.required }),
        zip: value => (value === null || value === '') ? true : this.zipValid(value)
      },
      state: {
        required: requiredIf(function () { return this.l10nStore.current.formFields.state.required }),
        state: value => (value === null || value === '') ? true : !!value.match(/\S/)
      },
      city: {
        required: requiredIf(function () { return this.l10nStore.current.formFields.city.required }),
        city: value => (value === null || value === '') ? true : !!value.match(/\S/)
      },
      country: {
        required,
        country: value => value !== 'none'
      }
    }
  },
  watch: {
    country: function (val) {
      const lParamGiven = this.rootStore.queryParams['l']
      if (this.rootStore.isEnDomain && (!lParamGiven)) {
        this.updateOfficeFromCountryCode(val)
      }
    },
    validateAllFields: function (_val) { this.v$.$touch() },
    'v$.$invalid': function (val) { this.rootStore.setValidationForm(!val) }
  }
}
</script>

<style lang="scss" scoped>
  .donation-form {
    margin: 0.1em 0 0;

    .full-details{
      height: auto !important;
      @include on-non-mobile(){
        display: grid;
        grid-gap: 1rem;
        grid-row-gap: 0;
        grid-template-columns: 1rem 1fr 1rem;
        grid-template-areas:
          '. header .'
          '. name .'
          '. email .'
          '. phone .'
          '. street .'
          '. city .'
          '. zip .'
          '. state .'
          '. country .';
      }

      &.no-state{
        grid-template-areas:
          '. header .'
          '. name .'
          '. email .'
          '. phone .'
          '. street .'
          '. city .'
          '. zip .'
          '. country .';
      }

      &.no-phone{
        grid-template-areas:
          '. header .'
          '. name .'
          '. email .'
          '. street .'
          '. city .'
          '. zip .'
          '. state .'
          '. country .';
      }

      &.no-phone-or-state{
        grid-template-areas:
          '. header .'
          '. name .'
          '. email .'
          '. street .'
          '. city .'
          '. zip .'
          '. country .';
      }

      h3 {
        margin: 0.75em 0 0.5em;
        color: white;
        font-family: $titles-font-family-medium;

        &.details-header{
          margin-bottom: 0;
          grid-area: header;
        }
      }
      & > .name{
        grid-area: name;

        @include on-non-mobile(){
          display: grid;
          grid-auto-flow: column;
          grid-gap: 1rem;
        }
      }
      & > .email{
        grid-area: email;
      }
      & > .phone{
        grid-area: phone;
      }
      & > .street{
        grid-area: street;
      }
      & > .zip{
        grid-area: zip;
      }
      & > .city{
        grid-area: city;
      }
      & > .state{
        grid-area: state;
      }
      & > .country{
        grid-area: country;
      }
    }

    .readonly-val {
      margin-top: 2rem;
      padding: 0.5rem;
      background-color: rgba(255, 255, 255, 0.3);
      color: $color-faded-btn-text;
    }
  }
</style>
