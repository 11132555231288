<template>
  <div class="paypal-payment">
    <small>{{ $t('payment.methods.paypal.popUpWarning') }}</small>
    <SubmitButton @submitted="processDonation" />
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import axios from 'axios'
import reportFailureToBugsnag from '@/utilities/reportFailureToBugsnag'
import storePaymentDetails from '../../lib/payment/storePaymentDetails'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'PaypalComponent',
  components: { SubmitButton },
  setup () {
    const paymentStore = usePaymentStore()
    const donationStore = useDonationStore()
    return { paymentStore, donationStore }
  },
  mounted () {
    this.paymentStore.setComplete(true)
  },
  methods: {
    processDonation (_recaptchaResponse) {
      const donation = this.donationStore.currentDonation
      return axios.post(
        `${process.env.VUE_APP_BACKEND_HOST}/paypal/serialize`,
        {
          currency: donation.currency,
          amount: donation.amount,
          location: this.$l10n.currentLocation.key,
          language: this.$l10n.currentLanguage,
          type: donation.type,
          return_url: `${location.origin}/callbacks/paypal/authorised?l=${this.$l10n.currentLocation.key}&paypal_redirect=true`
        }).then((response) => {
          const details = { paypalId: response.data.paypal_id }
          this.paymentStore.setDetails(details)

          storePaymentDetails(this, localStorage)
          const storedPayment = this.paymentStore.details

          if (storedPayment) {
            return { approveUrl: response.data.approve }
          } else {
            return Promise.reject(new Error('Payment could not be stored'))
          }
        }).then(data => {
          window.location.href = data.approveUrl
          return new Promise(() => setTimeout(() => { console.log('Waiting for window location change ...') }, 10000))
        }).catch((error) => {
          reportFailureToBugsnag(error, this)
          this.paymentStore.setPaymentSuccess(false)
          this.paymentStore.setPaymentProcessing(true)
          this.paymentStore.setComplete(true)
          this.paymentStore.setPaymentResult(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
