<template>
  <div class="page-body">
    <div class="loading-panel-overlay">
      <div class="loading-panel">
        <div class="loading">
          <font-awesome-icon
            icon="spinner"
            pulse
          />
          <h4>{{ $t('paymentStatus.loading') }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useDonationStore } from '@/stores/donation'
import { useHead } from '@vueuse/head'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import awaitUpdate from '@/lib/donation/awaitUpdate'
import axios from 'axios'
import loadDonation from '@/lib/donation/loadDonation'
import loadPaymentDetails from '@/lib/payment/loadPaymentDetails'

export default {
  name: 'StripeAuthorisedCallback',
  setup () {
    const paymentStore = usePaymentStore()
    const donationStore = useDonationStore()
    const rootStore = useRootStore()
    const siteData = reactive({ title: '' })
    useHead({ title: computed(() => siteData.title) })

    return { siteData, rootStore, paymentStore, donationStore }
  },
  beforeMount () {
    this.siteData.title = `${this.$t('paymentStatus.loading')} - Stripe`
  },
  computed: {
    token () { return this.rootStore.queryParams.token }
  },
  methods: {
    submit (donation) {
      axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation`, donation)
        .then(() => awaitUpdate(this, donation.reference))
        .then((result) => { if (result.status === 'failed') { this.handleFailure() } })
        .then(() => { this.$router.push({ name: this.$t('routeNames.thankYou') }) })
        .catch((error) => {
          this.$bugsnag.notify(error)
          this.handleFailure()
        })
    },
    handleFailure (_errMsg = null) {
      const queryObject = { payment_cancelled: true }
      if (localStorage.location) { queryObject.l = localStorage.location }
      // add logic to PaymentDetails to populate submissionPromise with error to show supporter something went wrong.
      this.$router.push({ path: '/', query: queryObject })
    }
  },
  mounted () {
    const storedDonation = loadDonation(this, localStorage) // err unless present
    const storedPayment = loadPaymentDetails(this, localStorage) // err unless present
    if (storedDonation && storedPayment) {
      const donation = Object.assign(
        {},
        storedDonation,
        {
          payment: {
            token: this.token,
            adapter: 'stripe',
            payment_intent_id: this.paymentStore.details.paymentIntentId
          },
          location: this.$l10n.currentLocation.key,
          language: this.$l10n.currentLanguage
        }
      )
      this.submit(donation)
    } else { this.handleFailure() }
  }
}
</script>

<style lang="scss" scoped>
  .page-body {
    color: white;
    position: relative;
    overflow: initial;
    background-color: white;
    max-height: initial;
    min-height: 60vh;
  }

  .loading-panel-overlay {
    background-color: rgba(black, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5000;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    .loading-panel {
      background-color: white;
      padding: 1em;
      display: inline-block;
      color: black;
      width: 40%;
      min-width: 300px;

      @include on-non-mobile(){
        min-width: 400px;
      }

      .fa-spinner {
        width: 2em;
        height: 2em;
      }

      h4 {
        margin: 0;
      }
    }
  }
</style>
